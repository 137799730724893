import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { BrowserRouter } from 'react-router-dom'

export type Props = {
  children: React.ReactNode
}

// the inferval we want to force an actual page reload on to ensure
// the client is on the current version of the page
const REFRESH_MAX_INTERVAL = 1000 * 60 * 30 // every 30 minutes

/**
 * Component keeping track of how long the page has been open for, and
 * when the location changes it checks if the threshold has been reached
 * and triggers an actual page load (as opposed to client side page
 * transition done through react router)
 */
function RouteChangeListener({ children }: Props) {
  const [shouldRefresh, setShouldRefresh] = useState(false)
  const { pathname } = useLocation()
  const lastPathRef = useRef(location.pathname)
  const lastPathname = lastPathRef.current

  useEffect(() => {
    // The path didn't change. Do nothing
    if (pathname === lastPathname) {
      return
    }

    // The page should not be reloaded. Update the last path
    if (!shouldRefresh) {
      lastPathRef.current = pathname
      return
    }

    // The location has changed and the threshold has been reached
    window.location.href = pathname
  }, [pathname, shouldRefresh, lastPathname])

  useEffect(() => {
    const id = setTimeout(
      () => setShouldRefresh(true),
      REFRESH_MAX_INTERVAL,
    )

    return () => clearTimeout(id)
  }, [])

  return <>{children}</>
}

export default function RefreshRouter({ children }: Props) {
  return <BrowserRouter>
    <RouteChangeListener>{children}</RouteChangeListener>
  </BrowserRouter>
}
