import classNames from 'classnames'
import { forwardRef, HTMLAttributes } from 'react'

import styles from './Checkbox.module.scss'

export type Props = HTMLAttributes<HTMLInputElement>

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ className, style, ...props }, ref) => (
    <input
      ref={ref}
      className={classNames(styles.Checkbox)}
      style={style}
      type="checkbox"
      {...props}
    />
  ),
)
