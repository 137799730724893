import React, { CSSProperties, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import throttle from 'lodash/throttle'
import classNames from 'classnames'

import styles from './Portal.module.scss'
import { ReminUI } from '../ReminUI'

export type Props = {
  children?: React.ReactNode
  style?: React.CSSProperties
  overlayStyle?: React.CSSProperties
  className?: string
  fadeBackground?: boolean
  clickThrough?: boolean
  /** Must be used for animation as the component must be mounted while exiting */
  show?: boolean
  timeout?: number
  mountNode?: HTMLElement | null
  relativeTo?: HTMLElement | null
  restrictWidthToRelativeElement?: boolean
  onClickOutside?: () => void
}

/**
 * The Portal component renders all children in a separate DOM context that lies next to the body.
 * This allows escaping the rendering context of the parent component.
 *
 * Animation:
 * - The Portal component includes animation and will by default add --exit and --enter modifiers
 * to the className of the provided child
 */
export function Portal({
  children,
  style = {},
  overlayStyle = {},
  fadeBackground = false,
  show = true,
  timeout = 300,
  clickThrough = false,
  relativeTo,
  restrictWidthToRelativeElement = true,
  onClickOutside,
  className,
  mountNode = document.body,
}: Props) {
  const [showPortal, setShowPortal] = useState(show)
  const [inlineStyle, setInlineStyle] = useState<CSSProperties | null>(null)

  // Keep the Portal visible while the animation finishes
  useEffect(() => {
    if (show) {
      setShowPortal(true)
    }
  }, [show])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updatePosition = useCallback(
    throttle(() => {
      const style: CSSProperties = {}
      if (relativeTo != null && mountNode != null) {
        const pos = relativeTo.getBoundingClientRect()
        const mountNodePos = mountNode.getBoundingClientRect()
        style.top = `${pos.top - mountNodePos.top + pos.height}px`
        style.left = `${pos.left - mountNodePos.left}px`
        if (restrictWidthToRelativeElement) {
          style.width = pos.width
        }
        setInlineStyle(style)
      }
    }, 25),
    [mountNode, relativeTo, restrictWidthToRelativeElement],
  )

  useLayoutEffect(() => {
    if (relativeTo == null || mountNode == null || !show) {
      return
    }

    // Update the position when the user scrolles in the mountNode
    mountNode.addEventListener('scroll', updatePosition, true)
    // Update position when a new DOM node enters the mountNode's subtree
    const observer = new MutationObserver(() => updatePosition())
    // Update position if the mountNode or a sub-node is resized
    const observer2 =
      window?.ResizeObserver != null ? new ResizeObserver(() => updatePosition()) : undefined
    const observer3 =
      window?.IntersectionObserver != null
        ? new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                // Hide the portal if the relative element is not intersecting with the
                // viewport of mountNode - typically if it's scrolled outside
                if (!entry.isIntersecting) {
                  setShowPortal(false)
                } else {
                  setShowPortal(show)
                }
              })
              updatePosition()
            },
            { root: mountNode },
          )
        : undefined
    observer.observe(mountNode, { subtree: true, attributes: true })
    observer2?.observe(mountNode)
    observer3?.observe(relativeTo)

    updatePosition()

    return () => {
      observer.disconnect()
      observer2?.disconnect()
      observer3?.disconnect()
      mountNode.removeEventListener('scroll', updatePosition)
    }
  }, [relativeTo, mountNode, show, updatePosition])

  if (mountNode == null || !showPortal) {
    return <></>
  }

  let combinedStyle = style
  if (inlineStyle != null) {
    combinedStyle = {
      ...inlineStyle,
      // Override the inlineStyle with the combined style to allow more flexibility
      ...combinedStyle,
    }
  }

  const onExited = () => setShowPortal(false)

  /**
   * Render two portals
   * 1. Background portal that fades the background and receives the onClickOutside event
   * 2. The portal that contains the children
   */
  return (
    <>
      {createPortal(
        <CSSTransition
          in={show}
          timeout={timeout}
          onExited={onExited}
          classNames={{
            appearDone: `${styles.overlay}`,
            enterDone: `${styles.overlay}`,
            exit: `${styles['overlay--exit']}`,
            exitActive: `${styles['overlay--exit']}`,
            exitDone: `${styles['overlay--exit']}`,
          }}
        >
          <ReminUI
            className={classNames(styles.overlay, {
              [styles['overlay--fade-background']]: fadeBackground,
              [styles['overlay--click-through']]:
                clickThrough === null ? fadeBackground : clickThrough,
            })}
            onClick={onClickOutside}
            style={overlayStyle}
          />
        </CSSTransition>,
        mountNode,
      )}
      {createPortal(
        <CSSTransition in={show} timeout={timeout} classNames={`${className ?? ''}-`}>
          <ReminUI className={classNames(styles.portal, className)} style={combinedStyle}>
            {children}
          </ReminUI>
        </CSSTransition>,

        mountNode,
      )}
    </>
  )
}
