import './styles/main.scss'

import React, { PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'
import RefreshRouter from 'components/RefreshRouter'
import { ApolloWrapper } from 'webservice'
import useSystemStatusMessage from 'hooks/useSystemStatusMessage'
import { Col } from 'ui'

export const BUILT_TARGET = process.env.REACT_APP_BUILD_TARGET ?? 'tracing'

const BUILD_TARGETS: Record<string, () => Promise<{ default: Function }>> = {
  tracing: async () => await import('./apps/tracing'),
  booking: async () => await import('./apps/booking'),
  selftest: async () => await import('./apps/selftest'),
  'questionnaire-graphql': async () => await import('./apps/questionnaire-graphql'),
}

// Determine which entry point to import, or throw error if bad build target
const loadTarget =
  BUILD_TARGETS[BUILT_TARGET] ??
  (async () => {
    const err = new Error('App not found')
    err.name = 'APP_NOT_FOUND'

    return await Promise.reject(err)
  })

// Load the application
loadTarget()
  .then(({ default: BuildTarget }) => {
    ReactDOM.render(
      <ApolloWrapper>
        <ApplicationDown>
          <RefreshRouter>
            <BuildTarget />
          </RefreshRouter>
        </ApplicationDown>
      </ApolloWrapper>,
      document.getElementById('root'),
    )
  })
  .catch((err) => {
    if (err.name !== 'APP_NOT_FOUND') {
      return Promise.reject(err)
    }

    // Render error if unknown/bad build target
    ReactDOM.render(
      <div style={{ width: '100%', maxWidth: '500px', margin: '50px auto 0' }}>
        <h1>BUILD ERROR</h1>
        <p>
          Unknown build target specified: <code>{process.env.REACT_APP_BUILD_TARGET}</code>
        </p>
        <p>
          The build target must either not be set (it defaults to the tracing application) or set to
          one of the build targets declared in <code>src/index.js</code>.
        </p>
        <p>
          Known build targets:{' '}
          {Object.keys(BUILD_TARGETS).map((name, i) => (
            <span key={name}>
              {i > 0 ? ', ' : ''}
              <code>{name}</code>
            </span>
          ))}
        </p>
      </div>,
      document.getElementById('root'),
    )
  })

const ApplicationDown = ({ children }: PropsWithChildren<{}>) => {
  const { applicationUnavailable, tracerSystemMessage } = useSystemStatusMessage()
  if (applicationUnavailable) {
    return (
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          backgroundColor: '#f4d157',
          zIndex: 10000,
          padding: '40px',
          overflow: 'hidden',
        }}
      >
        <Col center style={{ gap: '16px' }}>
          <svg width="24px" height="24px" viewBox="0 0 24 24">
            <g>
              <path d="M12,0C5.37,0,0,5.37,0,12c0,6.63,5.37,12,12,12s12-5.37,12-12C24,5.37,18.63,0,12,0z M13.67,19.17 c-0.38,0.38-0.89,0.56-1.54,0.56c-0.7,0-1.26-0.16-1.69-0.47C10,18.95,9.79,18.49,9.79,17.9c0-0.64,0.19-1.14,0.56-1.5 c0.38-0.36,0.89-0.54,1.54-0.54c0.71,0,1.28,0.15,1.7,0.45c0.43,0.3,0.64,0.75,0.64,1.36C14.24,18.29,14.05,18.79,13.67,19.17z M14.08,9.25c-0.4,1.87-0.82,3.44-1.25,4.71c-0.16,0.01-0.4,0.02-0.72,0.02s-0.57-0.01-0.72-0.02c-0.47-1.35-0.93-2.95-1.38-4.81 S9.28,5.82,9.21,4.74c1.86-0.17,3.72-0.17,5.58,0C14.72,5.87,14.48,7.38,14.08,9.25z"></path>
            </g>
          </svg>
          <strong>{tracerSystemMessage}</strong>
        </Col>
      </div>
    )
  }
  return <>{children}</>
}
